.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: rgba(0, 12, 46, 0.3);
  border-radius: 20px;
}

.blog-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.blog-header h1 {
  font-size: 3.5rem;
  background: linear-gradient(135deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
  text-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
}

.tag-filters {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
  padding: 20px;
  background: rgba(0, 12, 46, 0.5);
  border-radius: 16px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.tag-button {
  padding: 8px 20px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 20px;
  background: rgba(0, 12, 46, 0.7);
  color: var(--color-neon-blue);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tag-button:hover {
  background: rgba(0, 240, 255, 0.1);
  border-color: var(--color-neon-blue);
  transform: translateY(-2px);
  color: var(--color-neon-blue);
}

.tag-button.active {
  background: var(--color-neon-blue);
  color: var(--color-background);
  border-color: var(--color-neon-blue);
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.3);
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 30px;
}

.blog-card {
  background: rgba(0, 12, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.4s ease;
  position: relative;
}

.blog-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent, 
    rgba(0, 240, 255, 0.2), 
    transparent
  );
  transform: scaleX(0);
  transition: transform 0.4s ease;
}

.blog-card:hover {
  transform: translateY(-8px);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.2),
    0 0 20px rgba(0, 240, 255, 0.1);
}

.blog-card:hover::before {
  transform: scaleX(1);
}

.blog-card-content {
  padding: 30px;
}

.blog-tag {
  display: inline-block;
  padding: 6px 16px;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 20px;
  font-size: 0.8rem;
  color: var(--color-neon-blue);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.blog-card h2 {
  font-size: 1.4rem;
  color: var(--color-text-primary);
  margin-bottom: 16px;
  line-height: 1.4;
  min-height: 80px;
}

.blog-date {
  display: block;
  font-size: 0.875rem;
  color: var(--color-text-tertiary);
  margin-bottom: 20px;
  opacity: 0.8;
}

.read-more {
  display: inline-flex;
  align-items: center;
  color: var(--color-neon-blue);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 8px 0;
  position: relative;
}

.read-more::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--color-neon-blue);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.read-more:hover {
  color: var(--color-neon-purple);
}

.read-more:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

@media (max-width: 768px) {
  .blog-container {
    padding: 20px 16px;
  }

  .blog-header h1 {
    font-size: 2.2rem;
  }

  .blog-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .tag-filters {
    padding: 16px;
    gap: 8px;
  }

  .tag-button {
    padding: 6px 16px;
    font-size: 0.8rem;
  }

  .blog-card-content {
    padding: 24px;
  }

  .blog-card h2 {
    font-size: 1.2rem;
    min-height: auto;
  }
}

.content-type-filters {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 24px;
}

.type-button {
  padding: 10px 24px;
  border: none;
  border-radius: 30px;
  background: rgba(0, 12, 46, 0.9);
  color: var(--color-text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
}

.type-button:hover {
  background: rgba(0, 240, 255, 0.1);
}

.type-button.active {
  background: linear-gradient(135deg, var(--color-neon-blue), var(--color-neon-purple));
  color: var(--color-background);
}

.paper-card {
  background: rgba(0, 12, 46, 0.95);
  border: 1px solid var(--color-neon-purple);
}

.paper-card::before {
  background: linear-gradient(135deg, var(--color-neon-purple), var(--color-neon-blue));
}

.blog-summary {
  color: var(--color-text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 12px 0;
  opacity: 0.9;
} 