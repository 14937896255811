:root {
  --color-midnight: #000C2E;
  --color-platinum: #E5E5E5;
  --color-neon-blue: #00F0FF;
  --color-neon-purple: #7B00FF;
  --color-background: #000C2E;
  --color-background-secondary: rgba(0, 12, 46, 0.8);
  --color-background-tertiary: rgba(0, 12, 46, 0.6);
  --color-text-primary: #E5E5E5;
  --color-text-secondary: #B0B0B0;
  --color-border: rgba(0, 240, 255, 0.1);
  --color-primary: #00F0FF;
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-midnight);
  color: var(--color-platinum);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.services-container,
.llm-services-container {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}

.services-container h1,
.llm-services-container h1 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(123, 0, 255, 0.3);
}

.services-grid,
.llm-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  margin-top: 3rem;
}

.service-card,
.llm-service-card {
  padding: 2rem;
  border-radius: 12px;
  background: rgba(0, 12, 46, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.service-card::before,
.llm-service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, var(--color-neon-blue), var(--color-neon-purple));
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.service-card:hover,
.llm-service-card:hover {
  transform: translateY(-10px);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 10px 30px rgba(0, 240, 255, 0.1);
}

.service-card:hover::before,
.llm-service-card:hover::before {
  transform: scaleX(1);
}

.service-card h2,
.llm-service-card h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--color-neon-blue);
}

.service-card p,
.llm-service-card p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--color-platinum);
  opacity: 0.9;
}

@media (max-width: 768px) {
  .services-container h1,
  .llm-services-container h1 {
    font-size: 2.5rem;
  }
  
  .services-grid,
  .llm-services-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
}

.featured-card {
  background: linear-gradient(
    135deg,
    rgba(0, 12, 46, 0.9) 0%,
    rgba(123, 0, 255, 0.1) 100%
  ) !important;
  border: 1px solid rgba(0, 240, 255, 0.2) !important;
}

.card-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: rgba(0, 12, 46, 0.9);
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid var(--color-neon-blue);
  color: var(--color-neon-blue);
  box-shadow: 
    0 0 10px rgba(0, 240, 255, 0.2),
    inset 0 0 5px rgba(0, 240, 255, 0.2);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 
      0 0 10px rgba(0, 240, 255, 0.2),
      inset 0 0 5px rgba(0, 240, 255, 0.2);
  }
  50% {
    box-shadow: 
      0 0 20px rgba(0, 240, 255, 0.4),
      inset 0 0 10px rgba(0, 240, 255, 0.4);
  }
  100% {
    box-shadow: 
      0 0 10px rgba(0, 240, 255, 0.2),
      inset 0 0 5px rgba(0, 240, 255, 0.2);
  }
}

.card-icon {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.cube-container {
  perspective: 1000px;
  width: 60px;
  height: 60px;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 20s infinite linear;
}

.face {
  position: absolute;
  width: 60px;
  height: 60px;
  background: rgba(0, 240, 255, 0.1);
  border: 2px solid var(--color-neon-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--color-neon-blue);
  text-shadow: 0 0 10px var(--color-neon-blue);
}

.front  { transform: rotateY(0deg) translateZ(30px); }
.back   { transform: rotateY(180deg) translateZ(30px); }
.right  { transform: rotateY(90deg) translateZ(30px); }
.left   { transform: rotateY(-90deg) translateZ(30px); }
.top    { transform: rotateX(90deg) translateZ(30px); }
.bottom { transform: rotateX(-90deg) translateZ(30px); }

@keyframes rotate {
  from { transform: rotateX(0deg) rotateY(0deg); }
  to { transform: rotateX(360deg) rotateY(360deg); }
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 15px 0 0 0;
}

.feature-list li {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.95rem;
  color: var(--color-platinum);
}

.featured-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 
    0 20px 40px rgba(0, 240, 255, 0.15),
    0 0 30px rgba(123, 0, 255, 0.1);
}

/* Enhance existing card hover effects */
.service-card:hover h2 {
  text-shadow: 0 0 15px var(--color-neon-blue);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cube-container {
    transform: scale(0.8);
  }
  
  .card-badge {
    right: 15px;
    padding: 4px 12px;
    font-size: 0.75rem;
  }
}

/* Add these styles after the existing .services-container styles */

.coming-soon-features {
  margin-top: 2rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.coming-soon-features h3 {
  color: var(--color-neon-blue);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  margin: 0.8rem 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-platinum);
  opacity: 0.9;
}

.featured-card {
  grid-column: 1 / -1;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.featured-card h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-card p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

/* Technology Page Styles */
.tech-showcase {
  padding: 2rem 0;
}

.tech-section {
  margin-bottom: 4rem;
}

.tech-section-title {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.tech-feature {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.tech-feature:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-blue);
  box-shadow: 0 10px 20px rgba(0, 240, 255, 0.1);
}

.tech-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.tech-feature h3 {
  color: var(--color-neon-blue);
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.pipeline-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.pipeline-stage {
  background: rgba(0, 12, 46, 0.7);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.stage-badge {
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.coming-soon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.soon-feature {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(123, 0, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.soon-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(123, 0, 255, 0.2);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  color: var(--color-neon-purple);
  border: 1px solid var(--color-neon-purple);
  animation: pulse 2s infinite;
}

.soon-feature h3 {
  color: var(--color-neon-blue);
  margin: 1rem 0;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .tech-section-title {
    font-size: 1.8rem;
  }
  
  .tech-grid, .pipeline-container, .coming-soon-grid {
    grid-template-columns: 1fr;
  }
}

/* Solutions Page Styles */
.solutions-showcase {
  padding: 2rem 0;
}

.featured-solution {
  background: linear-gradient(135deg, rgba(0, 12, 46, 0.9), rgba(123, 0, 255, 0.1));
  border-radius: 15px;
  padding: 3rem 2rem;
  margin-bottom: 4rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.solution-content h2 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solution-tagline {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: var(--color-platinum);
  opacity: 0.9;
}

.solution-highlights {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background: rgba(0, 240, 255, 0.1);
  padding: 0.8rem 1.2rem;
  border-radius: 30px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.highlight-icon {
  font-size: 1.4rem;
}

.highlight-text {
  font-size: 1.1rem;
  color: var(--color-platinum);
}

.solutions-section {
  margin-bottom: 4rem;
}

.section-title {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solution-card {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.solution-card:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-blue);
  box-shadow: 0 10px 20px rgba(0, 240, 255, 0.1);
}

.solution-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.solution-features {
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
}

.solution-features li {
  margin: 0.5rem 0;
  color: var(--color-platinum);
  opacity: 0.9;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.solution-features li::before {
  content: '→';
  color: var(--color-neon-blue);
}

.industry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.industry-card {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(123, 0, 255, 0.2);
  position: relative;
  transition: all 0.3s ease;
}

.industry-card:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-purple);
  box-shadow: 0 10px 20px rgba(123, 0, 255, 0.1);
}

.industry-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(123, 0, 255, 0.2);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  color: var(--color-neon-purple);
  border: 1px solid var(--color-neon-purple);
}

.industry-card h3 {
  color: var(--color-neon-blue);
  margin: 1rem 0;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .solution-content h2 {
    font-size: 2rem;
  }
  
  .solution-tagline {
    font-size: 1.2rem;
  }
  
  .highlight-item {
    width: 100%;
  }
  
  .industry-grid {
    grid-template-columns: 1fr;
  }
}

/* Navigation Styles */
.nav-link {
  position: relative;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  color: var(--color-platinum);
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--color-neon-blue);
}

.nav-link.active {
  color: var(--color-neon-blue);
}

.nav-indicator {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(
    90deg,
    var(--color-neon-blue),
    var(--color-neon-purple)
  );
  border-radius: 1px;
  box-shadow: 0 0 8px rgba(0, 240, 255, 0.5);
}

/* Update existing navbar styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  transition: all 0.3s ease;
  z-index: 1000;
  background: rgba(0, 12, 46, 0.95);
  backdrop-filter: blur(10px);
}

.navbar.scrolled {
  padding: 1rem 4rem;
  background: rgba(0, 12, 46, 0.95);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.nav-links .cta-button {
  margin-left: 0.8rem;
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem 2rem;
    top: 0;
  }
  
  .navbar.scrolled {
    padding: 0.8rem 1rem;
  }
  
  .nav-links {
    display: none; /* You might want to implement a mobile menu instead */
  }
}

/* AI Playground Styles */
.playground-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.playground-card {
  background: rgba(0, 12, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 15px;
  padding: 2rem;
  height: 300px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.playground-card h3 {
  color: var(--color-neon-blue);
  margin: 0;
  font-size: 1.4rem;
  z-index: 2;
}

.playground-card p {
  color: var(--color-platinum);
  margin: 0.5rem 0 0 0;
  opacity: 0.8;
  z-index: 2;
}

/* Neural Dance */
.neural-visualization {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 2rem;
}

.neuron {
  background: radial-gradient(circle at center, var(--color-neon-blue), transparent);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/* Matrix Rain */
.matrix-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.matrix-column {
  color: var(--color-neon-blue);
  font-family: monospace;
  font-size: 0.8rem;
  opacity: 0.6;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

/* Quantum Field */
.quantum-particles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.particle {
  position: absolute;
  width: 8px;
  height: 8px;
  background: var(--color-neon-purple);
  border-radius: 50%;
  box-shadow: 0 0 10px var(--color-neon-blue);
}

/* Hover effects */
.playground-card:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 240, 255, 0.1),
    rgba(123, 0, 255, 0.1)
  );
  z-index: 1;
}

/* Quantum Simulator Styles */
.quantum-simulator {
  background: linear-gradient(
    135deg,
    rgba(0, 12, 46, 0.95),
    rgba(123, 0, 255, 0.15)
  ) !important;
}

.quantum-visualization {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.qubit-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 2rem;
  transform: perspective(1000px) rotateX(30deg);
}

.qubit {
  position: relative;
  width: 40px;
  height: 40px;
}

.qubit-core {
  position: absolute;
  width: 8px;
  height: 8px;
  background: var(--color-neon-blue);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px var(--color-neon-blue);
}

.qubit-orbit {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 50%;
  animation: orbit 4s linear infinite;
}

.qubit-particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: var(--color-neon-purple);
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform-origin: 0 20px;
  box-shadow: 0 0 10px var(--color-neon-purple);
}

@keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.quantum-simulator:hover .qubit-orbit {
  border-color: rgba(0, 240, 255, 0.6);
}

.quantum-simulator:hover .qubit-core {
  box-shadow: 0 0 25px var(--color-neon-blue);
}

.quantum-simulator:hover .qubit-particle {
  box-shadow: 0 0 20px var(--color-neon-purple);
}

/* Quantum 2100 Feature Styles */
.quantum-2100-card {
  display: block;
  text-decoration: none;
  background: linear-gradient(
    135deg,
    rgba(0, 12, 46, 0.95),
    rgba(123, 0, 255, 0.2)
  );
  border-radius: 15px;
  padding: 3rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
}

.quantum-2100-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 240, 255, 0.05),
    rgba(123, 0, 255, 0.05)
  );
  opacity: 0.1;
  z-index: 0;
  transition: opacity 0.3s ease;
}

.quantum-2100-card:hover::before {
  opacity: 0.15;
}

.quantum-2100-content {
  position: relative;
  z-index: 1;
}

.project-badge {
  display: inline-block;
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.quantum-2100-card h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quantum-tagline {
  font-size: 1.2rem;
  color: var(--color-platinum);
  margin-bottom: 2rem;
  max-width: 600px;
  line-height: 1.6;
}

.feature-highlights {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.highlight {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--color-platinum);
}

.highlight-icon {
  font-size: 1.4rem;
}

.cta-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-neon-blue);
  font-size: 1.1rem;
  font-weight: 500;
}

.arrow {
  transition: transform 0.3s ease;
}

.quantum-2100-card:hover .arrow {
  transform: translateX(5px);
}

.quantum-2100-card:hover {
  border-color: var(--color-neon-blue);
  box-shadow: 
    0 20px 40px rgba(0, 240, 255, 0.1),
    0 0 100px rgba(123, 0, 255, 0.1);
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .quantum-2100-card {
    padding: 2rem;
  }
  
  .quantum-2100-card h3 {
    font-size: 2rem;
  }
  
  .feature-highlights {
    gap: 1rem;
  }
}

/* Project Page Styles */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.project-card {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-blue);
  box-shadow: 0 10px 20px rgba(0, 240, 255, 0.1);
}

.project-card h4 {
  color: var(--color-neon-blue);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.project-tech-stack {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.project-tech-stack span {
  background: rgba(0, 240, 255, 0.1);
  padding: 0.4rem 0.8rem;
  border-radius: 15px;
  font-size: 0.9rem;
  color: var(--color-neon-blue);
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.featured-project {
  margin-bottom: 4rem;
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}

/* About Page Teaser Styles */
.about-teaser {
  background: linear-gradient(
    135deg,
    rgba(0, 12, 46, 0.95),
    rgba(123, 0, 255, 0.15)
  );
  border-radius: 15px;
  padding: 3rem;
  text-align: center;
  border: 1px solid rgba(0, 240, 255, 0.2);
  max-width: 800px;
  margin: 0 auto;
}

.teaser-badge {
  display: inline-block;
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  animation: pulse 2s infinite;
}

.about-teaser h2 {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.teaser-text {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--color-platinum);
  margin-bottom: 2.5rem;
  opacity: 0.9;
}

.preview-stats {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.stat-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: var(--color-platinum);
  font-size: 1.1rem;
  font-weight: 500;
}

.teaser-footer {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.teaser-footer p {
  margin-bottom: 1rem;
  color: var(--color-platinum);
  opacity: 0.9;
}

.cta-button {
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.3);
}

@media (max-width: 768px) {
  .about-teaser {
    padding: 2rem;
    margin: 1rem;
  }

  .about-teaser h2 {
    font-size: 2rem;
  }

  .preview-stats {
    gap: 2rem;
  }
}

/* Mobile Navigation Styles */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1000;
}

.hamburger {
  display: block;
  width: 24px;
  height: 2px;
  background: var(--color-neon-blue);
  position: relative;
  transition: all 0.3s ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background: var(--color-neon-blue);
  transition: all 0.3s ease-in-out;
}

.hamburger::before {
  transform: translateY(-8px);
}

.hamburger::after {
  transform: translateY(8px);
}

.hamburger.open {
  background: transparent;
}

.hamburger.open::before {
  transform: rotate(45deg);
}

.hamburger.open::after {
  transform: rotate(-45deg);
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background: rgba(0, 12, 46, 0.95);
  backdrop-filter: blur(10px);
  padding: 60px 1rem 1rem;
  box-shadow: -5px 0 20px rgba(0, 0, 0, 0.3);
  z-index: 100;
  flex-direction: column;
  gap: 1.5rem;
}

.mobile-nav .nav-link {
  font-size: 1.1rem;
  padding: 0.6rem;
  width: 100%;
  text-align: left;
  border-radius: 8px;
}

.mobile-nav .nav-link:hover {
  background: rgba(0, 240, 255, 0.1);
}

/* Update existing nav-links class */
.nav-links.desktop-nav {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .nav-links.desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: flex;
  }

  .mobile-nav.open {
    transform: translateX(0);
  }
}

/* Add backdrop when mobile menu is open */
.App::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 90;
}

.App.menu-open::after {
  opacity: 1;
  visibility: visible;
}

/* Update mobile-specific styles */
@media (max-width: 768px) {
  /* Navbar mobile adjustments */
  .navbar {
    padding: 1rem 2rem;
  }
  
  .navbar.scrolled {
    padding: 0.8rem 1rem;
  }

  /* Mobile nav adjustments */
  .mobile-nav {
    padding: 60px 1rem 1rem;
  }

  .mobile-nav .nav-link {
    padding: 0.6rem;
    font-size: 1.1rem;
  }

  /* Page container adjustments */
  .page-container {
    padding: 1rem;
  }

  .page-content {
    padding: 1rem 0;
  }

  /* Section padding adjustments */
  .tech-section,
  .solutions-showcase,
  .projects-grid,
  .about-teaser {
    padding: 1rem 0;
  }

  /* Card padding adjustments */
  .tech-feature,
  .solution-card,
  .project-card,
  .about-teaser {
    padding: 1.5rem;
  }

  /* Grid gap adjustments */
  .tech-grid,
  .pipeline-container,
  .coming-soon-grid,
  .projects-grid {
    gap: 1rem;
  }

  /* Title size adjustments */
  .page-title {
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }

  .tech-section-title,
  .section-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

/* Add these styles to your existing CSS file */

.news-section {
  background: rgba(0, 12, 46, 0.7);
}

.news-container {
  max-width: 1200px;
  margin: 0 auto;
}

.news-container .section-title {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  text-align: center;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.news-card {
  background: rgba(0, 12, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.news-card:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-blue);
  box-shadow: 0 10px 30px rgba(0, 240, 255, 0.1);
}

.news-content {
  padding: 1.5rem;
}

.news-tag {
  display: inline-block;
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.news-card h3 {
  color: var(--color-platinum);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.news-card p {
  color: var(--color-platinum);
  opacity: 0.8;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.news-date {
  display: block;
  color: var(--color-platinum);
  opacity: 0.6;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .news-section {
    padding: 2rem 1rem;
  }
  
  .news-container .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .news-grid {
    grid-template-columns: 1fr;
  }
}

/* Hero Section */
.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

}

.hero-title {
  font-size: 4.5rem;
  text-align: center;
  margin-top: 150px;
  background: linear-gradient(45deg, 
    var(--color-neon-blue), 
    var(--color-neon-purple)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(0, 240, 255, 0.3);
  position: relative;
}

/* Metrics Bar */
.metrics-bar {
  display: flex;
  gap: 3rem;
  padding: 2rem;
  background: rgba(0, 12, 46, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.metric {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.metric-icon {
  position: relative;
  font-size: 1.8rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 240, 255, 0.1);
  border-radius: 50%;
}

.pulse-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--color-neon-blue);
  animation: pulse 2s infinite;
}

.metric-content {
  display: flex;
  flex-direction: column;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-neon-blue);
}

.metric-label {
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Main Content */
.main-content {
  background: rgba(0, 12, 46, 0.7);
  min-height: 100vh;
  padding: 4rem 2rem;
}

.content-grid {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 3rem;
}

/* Feature Cards */
.feature-card {
  position: relative;
  padding: 2rem;
  background: rgba(0, 12, 46, 0.95);
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  margin-bottom: 2rem;
  overflow: hidden;
}

.feature-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 0%,
    rgba(0, 240, 255, 0.1) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover .feature-glow {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .content-grid {
    grid-template-columns: 1fr;
  }
  
  .hero-title {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .metrics-bar {
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .hero-title {
    font-size: 2.5rem;
  }
}

/* Content Layout */
.content-wrapper {
  background: rgba(0, 12, 46, 0.7);
  padding: 4rem 0;
  margin-top: 2rem;
}

.content-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 380px;
  gap: 3rem;
}

.main-column {
  background: rgba(0, 12, 46, 0.5);
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.side-column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Feature Cards */
.feature-card {
  background: rgba(0, 12, 46, 0.95);
  border-radius: 20px;
  padding: 2rem;
  border: 1px solid rgba(0, 240, 255, 0.1);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  border-color: rgba(0, 240, 255, 0.3);
}

.feature-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .content-container {
    grid-template-columns: 1fr;
  }
  
  .side-column {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .feature-card {
    flex: 1;
    min-width: 300px;
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 2rem 0;
    margin-top: 1rem;
  }
  
  .content-container {
    padding: 0 1rem;
  }
  
  .side-column {
    flex-direction: column;
  }
  
  .feature-card {
    min-width: 100%;
  }
}

/* Update news ticker styles */
.news-ticker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 12, 46, 0.95);
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 100;
  backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.ticker-wrapper {
  flex: 1;
  overflow: hidden;
  position: relative;
}

/* Remove the gradient overlay */
.ticker-wrapper::after {
  display: none;
}

.ticker-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  animation: ticker 20s linear infinite;
  white-space: nowrap;
}

/* Add ticker animation */
@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Pause animation on hover */
.ticker-wrapper:hover .ticker-content {
  animation-play-state: paused;
}

.ticker-label {
  background: var(--color-neon-blue);
  color: var(--color-midnight);
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: nowrap;
  flex-shrink: 0;
}

.ticker-tag {
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.2rem 0.6rem;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
}

.ticker-text {
  color: var(--color-platinum);
  text-decoration: none;
  transition: color 0.3s ease;
}

.ticker-text:hover {
  color: var(--color-neon-blue);
}

/* Update responsive styles */
@media (max-width: 768px) {
  .news-ticker {
    padding: 0.5rem 1rem;
  }
  
  .ticker-label {
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
  }

  .ticker-tag {
    font-size: 0.7rem;
    padding: 0.1rem 0.4rem;
  }

  .ticker-text {
    font-size: 0.8rem;
  }
}

/* Update footer styles */
.footer {
  background: rgba(0, 12, 46, 0.95);
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 5rem 2rem 2rem;
  margin-top: 6rem;
}

.footer-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.footer-section h4 {
  color: var(--color-neon-blue);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.footer-section p,
.footer-section a {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--color-platinum);
  font-size: 0.95rem;
  opacity: 0.85;
  transition: all 0.3s ease;
  text-decoration: none;
}

.footer-section a:hover {
  color: var(--color-neon-blue);
  opacity: 1;
  transform: translateX(5px);
}

.footer-bottom {
  max-width: 1200px;
  margin: 4rem auto 0;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  text-align: center;
}

.disclaimer {
  color: var(--color-platinum);
  opacity: 0.7;
  font-size: 0.85rem;
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto 1.5rem;
}

.copyright {
  color: var(--color-platinum);
  opacity: 0.8;
  font-size: 0.85rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .footer {
    padding: 3rem 1.5rem 1.5rem;
    margin-top: 4rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

  .footer-section {
    text-align: center;
    align-items: center;
  }

  .footer-section a:hover {
    transform: none;
  }

  .footer-bottom {
    margin-top: 2.5rem;
    padding-top: 1.5rem;
  }

  .disclaimer {
    font-size: 0.8rem;
    padding: 0 1rem;
  }
}

/* Add startup banner styles */
.startup-banner {
  background: rgba(123, 0, 255, 0.1);
  border: 1px solid var(--color-neon-purple);
  border-radius: 12px;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 101; /* Higher than ticker */
}

.startup-badge {
  background: var(--color-neon-purple);
  color: var(--color-midnight);
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.startup-banner p {
  margin: 0;
  color: var(--color-platinum);
  font-size: 1.1rem;
}

.waitlist-link {
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  color: var(--color-midnight);
  text-decoration: none;
  font-weight: 600;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
}

.waitlist-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 30px rgba(0, 240, 255, 0.3);
  color: var(--color-midnight);
  text-decoration: none;
}

.waitlist-link:active {
  transform: translateY(0);
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.2);
}

@media (max-width: 768px) {
  .startup-banner {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .waitlist-link {
    width: 100%;
    justify-content: center;
  }
}

/* Update responsive styles */
@media (max-width: 768px) {
  .hero-container {
    padding-top: 80px;
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 12, 46, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.modal-content {
  background: var(--color-midnight);
  border: 1px solid var(--color-neon-purple);
  border-radius: 16px;
  padding: 2rem;
  max-width: 90%;
  width: 400px;
  position: relative;
  box-shadow: 0 0 30px rgba(123, 0, 255, 0.2);
}

.modal-header {
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
}

.modal-emoji {
  font-size: 3rem;
  display: block;
  margin-bottom: 1rem;
}

.modal-header h2 {
  color: var(--color-platinum);
  margin: 0;
  font-size: 1.8rem;
}

.modal-close {
  position: absolute;
  top: -1rem;
  right: -1rem;
  background: none;
  border: none;
  color: var(--color-platinum);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: var(--color-neon-blue);
}

.modal-body {
  text-align: center;
}

.modal-body p {
  margin: 1rem 0;
  color: var(--color-platinum);
  opacity: 0.9;
  line-height: 1.6;
}

.modal-linkedin-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.8rem 1.5rem;
  background: var(--color-neon-purple);
  color: var(--color-midnight);
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.modal-linkedin-button:hover {
  background: var(--color-neon-blue);
  transform: translateY(-2px);
}

/* Jaira Page Styles */
.jaira-showcase {
  padding: 2rem 0;
}

.jaira-hero {
  background: linear-gradient(45deg, rgba(0, 12, 46, 0.9), rgba(123, 0, 255, 0.2));
  padding: 4rem 2rem;
  border-radius: 20px;
  margin-bottom: 4rem;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.product-badge {
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-neon-blue);
  margin-bottom: 0.5rem;
}

.timeline {
  display: grid;
  gap: 2rem;
  margin: 3rem 0;
}

.timeline-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  position: relative;
}

.timeline-item.completed {
  border-left: 4px solid var(--color-neon-blue);
}

.timeline-item.current {
  border-left: 4px solid var(--color-neon-purple);
}

.timeline-date {
  color: var(--color-neon-blue);
  font-weight: 500;
}

.use-cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.use-case-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.use-case-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

@media (max-width: 768px) {
  .hero-stats {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
 .timeline {
    gap: 1rem;
  }
}

/* Add after the .solution-highlights styles */

.learn-more-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(
    45deg,
    var(--color-neon-blue),
    var(--color-neon-purple)
  );
  color: var(--color-midnight);
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
}

.learn-more-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    var(--color-neon-purple),
    var(--color-neon-blue)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.learn-more-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 30px rgba(0, 240, 255, 0.3);
}

.learn-more-link:hover::before {
  opacity: 1;
}

.learn-more-link span {
  position: relative;
  z-index: 1;
}

/* Add styles for the Jaira feature section */
.jaira-feature {
  background: linear-gradient(
    135deg,
    rgba(0, 12, 46, 0.95),
    rgba(123, 0, 255, 0.2)
  );
  border: 1px solid rgba(0, 240, 255, 0.3);
  position: relative;
  overflow: hidden;
}

.jaira-feature::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(0, 240, 255, 0.1) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.jaira-feature:hover::before {
  opacity: 1;
}

.solution-badge {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: rgba(123, 0, 255, 0.2);
  color: var(--color-neon-purple);
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  border: 1px solid var(--color-neon-purple);
  animation: pulse 2s infinite;
}

@media (max-width: 768px) {
  .learn-more-link {
    width: 100%;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .solution-badge {
    top: 1rem;
    right: 1rem;
    font-size: 0.8rem;
    padding: 0.3rem 0.8rem;
  }
}

/* Technical Architecture Section */
.tech-architecture-section {
  padding: 4rem 0;
}

.architecture-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.architecture-card {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.architecture-card:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-blue);
}

.arch-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.tech-features {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.tech-features li {
  margin: 0.5rem 0;
  color: var(--color-platinum);
  opacity: 0.8;
}

/* FAQ Section */
.faq-section {
  padding: 4rem 0;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.faq-item {
  background: rgba(0, 12, 46, 0.7);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.faq-item:hover {
  border-color: var(--color-neon-blue);
  background: rgba(0, 12, 46, 0.8);
}

.faq-item h4 {
  color: var(--color-neon-blue);
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .architecture-grid,
  .faq-grid {
    grid-template-columns: 1fr;
  }
}

/* Featured Product Section */
.featured-product {
  margin: 4rem 0;
  padding: 3rem;
  background: linear-gradient(
    135deg,
    rgba(0, 12, 46, 0.95),
    rgba(123, 0, 255, 0.2)
  );
  border-radius: 20px;
  border: 1px solid rgba(0, 240, 255, 0.3);
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 3rem;
  position: relative;
  overflow: hidden;
}

.featured-content {
  z-index: 1;
}

.featured-badge {
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 240, 255, 0.3);
}

.featured-product h2 {
  font-size: 2.5rem;
  margin: 0.5rem 0;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 1rem 0;
  max-width: 600px;
}

.featured-highlights {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
}

.highlight {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.highlight-icon {
  font-size: 1.2rem;
}

.featured-cta {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  color: var(--color-midnight);
  text-decoration: none;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.featured-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 30px rgba(0, 240, 255, 0.3);
}

.featured-visual {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tech-orb {
  width: 300px;
  height: 300px;
  background: radial-gradient(
    circle at center,
    rgba(0, 240, 255, 0.2),
    rgba(123, 0, 255, 0.1)
  );
  border-radius: 50%;
  position: relative;
  animation: float 6s ease-in-out infinite;
}

.tech-orb::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid rgba(0, 240, 255, 0.3);
  border-radius: 50%;
  animation: pulse 3s linear infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@media (max-width: 1024px) {
  .featured-product {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  .featured-visual {
    display: none;
  }

  .featured-highlights {
    flex-direction: column;
    gap: 1rem;
  }

  .featured-product h2 {
    font-size: 2rem;
  }
}

/* Add this to your existing CSS */
.chat-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  background: rgba(0, 12, 46, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  backdrop-filter: blur(10px);
}

.messages-container {
  height: 500px;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 1rem;
}

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  max-width: 80%;
}

.message.user {
  background: rgba(0, 240, 255, 0.1);
  margin-left: auto;
  color: var(--color-neon-blue);
}

.message.assistant {
  background: rgba(123, 0, 255, 0.1);
  margin-right: auto;
  color: var(--color-platinum);
}

.chat-input-form {
  display: flex;
  gap: 1rem;
}

.chat-input-form input {
  flex: 1;
  padding: 0.8rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  background: rgba(0, 12, 46, 0.8);
  color: var(--color-platinum);
}

.chat-input-form button {
  padding: 0.8rem 1.5rem;
  background: var(--gradient-1);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.chat-input-form button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.typing-indicator {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: var(--color-neon-blue);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

/* Jaira Avatar Chat Styles */
.jaira-chat-container {
  max-width: 400px; /* Reduced from 800px */
  margin: 0 auto;
  padding: 1rem;
  background: rgba(0, 12, 46, 0.4); /* More transparent */
  border-radius: 16px;
  border: 1px solid rgba(0, 240, 255, 0.05);
  backdrop-filter: blur(5px);
}

.jaira-avatar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem; /* Reduced spacing */
}

.jaira-avatar {
  width: 60px; /* Smaller avatar */
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-orb {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-core {
  width: 30px; /* Smaller core */
  height: 30px;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border-radius: 50%;
  position: absolute;
  animation: pulse 3s infinite ease-in-out;
}

.avatar-ring {
  width: 50px; /* Smaller ring */
  height: 50px;
  border: 1px solid var(--color-neon-blue); /* Thinner border */
  border-radius: 50%;
  position: absolute;
  animation: spin 10s linear infinite;
}

.messages-container {
  height: 300px; /* Reduced height */
  overflow-y: auto;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.message {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 8px;
  font-size: 0.9rem; /* Smaller text */
  max-width: 85%;
}

.jaira-status {
  font-size: 0.7rem; /* Smaller status text */
  padding: 0.3rem 0.8rem;
}

/* Update section styling */
.jaira-section {
  margin-top: 2rem; /* Reduced top margin */
  text-align: center;
}

.jaira-section h2 {
  font-size: 1.8rem; /* Smaller heading */
  margin-bottom: 0.5rem;
}

.jaira-section p {
  font-size: 1rem; /* Smaller description */
  margin-bottom: 1rem;
  opacity: 0.8;
}

/* Add these new styles */
.jaira-chat-sidebar {
  position: fixed;
  top: 50%;
  left: 0; /* Changed from right to left */
  transform: translateY(-50%);
  width: 420px;
  height: 85vh;
  max-height: 800px;
  background: linear-gradient(215deg, rgba(0, 12, 46, 0.95), rgba(0, 12, 46, 0.98));
  border-right: 2px solid rgba(0, 240, 255, 0.2); /* Changed from border-left */
  box-shadow: 10px 0 30px rgba(0, 240, 255, 0.05); /* Adjusted shadow direction */
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse; /* Reversed to keep toggle on right side */
  overflow: hidden;
}

.jaira-chat-sidebar.collapsed {
  width: 50px;
  transform: translateY(-50%) translateX(-370px); /* Slide most of it off screen */
  background: linear-gradient(215deg, rgba(0, 12, 46, 0.8), rgba(0, 12, 46, 0.9));
  border-right: 2px solid rgba(0, 240, 255, 0.1);
}

.chat-toggle {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  cursor: pointer;
  background: rgba(0, 12, 46, 0.95);
  border-left: 1px solid rgba(0, 240, 255, 0.1); /* Changed from border-right */
  transition: all 0.3s ease;
}

/* Sharper avatar */
.jaira-avatar-mini {
  width: 30px; /* Slightly smaller */
  height: 30px;
  position: relative;
  margin-top: 0.5rem;
}

.avatar-core {
  width: 16px; /* Adjusted size */
  height: 16px;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 10px var(--color-neon-blue);
}

.avatar-ring {
  width: 26px; /* Adjusted size */
  height: 26px;
  border: 1.5px solid var(--color-neon-blue);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 5px var(--color-neon-blue);
}

/* Crisper chat content */
.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--color-background);
  overflow: hidden;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  margin: 0.5rem 0;
  scrollbar-width: thin;
  scrollbar-color: var(--color-neon-blue) var(--color-background);
}

/* Custom scrollbar for WebKit browsers */
.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: var(--color-background);
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: var(--color-neon-blue);
  border-radius: 3px;
}

/* Sharper messages */
.message {
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 0.9rem;
  max-width: 85%;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.message.user {
  background: var(--color-background-secondary);
  border: 1px solid var(--color-neon-blue);
}

.message.assistant {
  background: var(--color-background-secondary);
  border: 1px solid var(--color-neon-purple);
}

/* Modern Chat Styles */
.jaira-chat-sidebar {
  position: fixed;
  top: 50%;
  left: 0; /* Changed from right to left */
  transform: translateY(-50%);
  width: 420px;
  height: 85vh;
  max-height: 800px;
  background: linear-gradient(215deg, rgba(0, 12, 46, 0.95), rgba(0, 12, 46, 0.98));
  border-right: 2px solid rgba(0, 240, 255, 0.2); /* Changed from border-left */
  box-shadow: 10px 0 30px rgba(0, 240, 255, 0.05); /* Adjusted shadow direction */
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse; /* Reversed to keep toggle on right side */
  overflow: hidden;
}

.jaira-chat-sidebar.collapsed {
  width: 50px;
  transform: translateY(-50%) translateX(-370px); /* Slide most of it off screen */
  background: linear-gradient(215deg, rgba(0, 12, 46, 0.8), rgba(0, 12, 46, 0.9));
  border-right: 2px solid rgba(0, 240, 255, 0.1);
}

.chat-toggle {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  cursor: pointer;
  background: rgba(0, 12, 46, 0.95);
  border-left: 1px solid rgba(0, 240, 255, 0.1); /* Changed from border-right */
  transition: all 0.3s ease;
}

.toggle-icon {
  color: var(--color-neon-blue);
  font-size: 1.2rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.chat-toggle:hover .toggle-icon {
  opacity: 1;
}

/* Modernized Avatar */
.jaira-avatar-mini {
  width: 35px;
  height: 35px;
  position: relative;
  margin-top: 1rem;
}

.avatar-orb {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-core {
  width: 18px;
  height: 18px;
  background: linear-gradient(135deg, var(--color-neon-blue), var(--color-neon-purple));
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.5);
  animation: pulse 2s infinite ease-in-out;
}

.avatar-ring {
  width: 30px;
  height: 30px;
  border: 1.5px solid var(--color-neon-blue);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.2);
  animation: spin 8s linear infinite;
}

/* Chat Content */
.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: transparent;
  overflow: hidden;
}

.jaira-avatar-section {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.jaira-status {
  font-size: 0.85rem;
  color: var(--color-neon-blue);
  background: rgba(0, 240, 255, 0.1);
  padding: 0.4rem 1rem;
  border-radius: 20px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

/* Messages Container */
.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  padding-bottom: 4rem; /* Significantly more space at bottom */
  display: flex;
  flex-direction: column;
  gap: 1.2rem; /* Increased gap between messages */
}

.message {
  padding: 1rem 1.2rem;
  margin-bottom: 1.2rem; /* More space between messages */
  border-radius: 14px;
  max-width: 85%;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.3px;
  text-align: left;
}

.message.user {
  margin-left: auto;
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.15), rgba(0, 240, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: var(--color-platinum);
}

.message.assistant {
  margin-right: auto;
  background: linear-gradient(135deg, rgba(123, 0, 255, 0.15), rgba(123, 0, 255, 0.05));
  border: 1px solid rgba(123, 0, 255, 0.2);
  color: var(--color-platinum);
}

/* Input Area */
.chat-input-form {
  position: relative;
  padding: 1.5rem 2rem; /* More padding around input */
  margin-top: 1rem; /* Space above input form */
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  background: rgba(0, 12, 46, 0.98);
  z-index: 2; /* Ensure input stays above messages */
}

.chat-input-form input {
  flex: 1;
  padding: 0.8rem 1.2rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  background: rgba(0, 12, 46, 0.6);
  color: var(--color-platinum);
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.chat-input-form input:focus {
  outline: none;
  border-color: var(--color-neon-blue);
  background: rgba(0, 12, 46, 0.8);
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.1);
}

.chat-input-form button {
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, var(--color-neon-blue), var(--color-neon-purple));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chat-input-form button:hover {
  transform: translateY(-1px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.button-text {
  font-size: 0.95rem;
}

.button-icon {
  font-size: 1.1rem;
}

/* Loading Animation */
.typing-indicator {
  display: flex;
  gap: 0.4rem;
  padding: 0.8rem;
  background: rgba(0, 12, 46, 0.4);
  border-radius: 20px;
  width: fit-content;
}

.typing-indicator span {
  width: 6px;
  height: 6px;
  background: var(--color-neon-blue);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); opacity: 0.3; }
  40% { transform: scale(1); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(1); opacity: 0.8; }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .jaira-chat-sidebar {
    width: 100%;
    height: 70vh;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    border-right: none;
    border-top: 2px solid rgba(0, 240, 255, 0.2);
    flex-direction: column;
  }

  .jaira-chat-sidebar.collapsed {
    transform: translateY(calc(100% - 50px));
    width: 100%;
    height: 70vh;
  }

  .chat-toggle {
    width: 100%;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1.5rem;
    border-left: none;
    border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  }
}

/* Modern Chat Styles - Complete Overhaul */
.jaira-chat-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 420px;
  background: linear-gradient(215deg, 
    rgba(0, 12, 46, 0.95) 0%,
    rgba(0, 12, 46, 0.98) 100%
  );
  border-right: 2px solid rgba(0, 240, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 1000;
  display: flex;
  flex-direction: row;
}

.chat-handle {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 120px;
  background: linear-gradient(215deg, 
    rgba(0, 12, 46, 0.9) 0%,
    rgba(0, 12, 46, 0.95) 100%
  );
  border-radius: 0 12px 12px 0;
  border: 2px solid rgba(0, 240, 255, 0.3);
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chat-toggle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}

.toggle-icon {
  color: var(--color-neon-blue);
  font-size: 1.5rem;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.jaira-avatar-mini {
  width: 35px;
  height: 35px;
  background: rgba(0, 12, 46, 0.9);
  border-radius: 50%;
  padding: 5px;
  border: 2px solid var(--color-neon-blue);
  transition: all 0.3s ease;
}

.chat-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Update message styles */
.message {
  padding: 1rem;
  border-radius: 12px;
  max-width: 85%;
  background: rgba(0, 12, 46, 0.7);
  border: 1px solid rgba(0, 240, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.message.user {
  margin-left: auto;
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
}

.message.assistant {
  margin-right: auto;
  background: rgba(123, 0, 255, 0.1);
  border-color: rgba(123, 0, 255, 0.3);
}

/* Mobile styles */
@media (max-width: 768px) {
  .jaira-chat-sidebar {
    top: auto;
    bottom: 0;
    width: 100%;
    height: 80vh;
    transform: translateY(calc(100% - 60px));
  }

  .chat-handle {
    top: -60px;
    right: 0;
    width: 100%;
    height: 60px;
    border-radius: 12px 12px 0 0;
    border: 2px solid rgba(0, 240, 255, 0.3);
    border-bottom: none;
  }

  .chat-toggle {
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;
  }
}

/* Chat styles */
.chat-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.jaira-chat-container {
  width: 100%;
  background: rgba(0, 12, 46, 0.95);
  border: 2px solid rgba(0, 240, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(0, 240, 255, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
}

.chat-header {
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Changed to space-between */

  background: linear-gradient(90deg, rgba(0, 12, 46, 0.98), rgba(0, 18, 66, 0.98));
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.chat-header-left {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.clear-chat-btn {
  background: none;
  border: none;
  color: rgba(0, 240, 255, 0.6);
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-chat-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  color: rgba(0, 240, 255, 0.9);
}

.clear-chat-btn span {
  font-size: 16px;
}

.toggle-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-neon-blue);
}

.messages-container {
  height: 400px;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message {
  padding: 1rem;
  border-radius: 12px;
  max-width: 85%;
  background: rgba(0, 12, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.message.user {
  margin-left: auto;
  background: rgba(0, 240, 255, 0.1);
}

.message.assistant {
  margin-right: auto;
  background: rgba(123, 0, 255, 0.1);
}

.chat-input-form {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.chat-input-form input {
  flex: 1;
  padding: 1rem;
  border-radius: 8px;
  background: rgba(0, 12, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: var(--color-text-primary);
}

.chat-input-form button {
  padding: 1rem 2rem;
  border-radius: 8px;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border: none;
  color: white;
  cursor: pointer;
}

.typing-indicator {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: var(--color-neon-blue);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

/* Modern Floating Chat */
.chat-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  pointer-events: none; /* Allow clicking through the wrapper */
}

.floating-chat {
  pointer-events: auto; /* Re-enable clicking for the chat itself */
  position: relative;
  background: rgba(0, 12, 46, 0.98);
  border-radius: 30px;
  box-shadow: 0 5px 20px rgba(0, 240, 255, 0.2);
  overflow: hidden;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 240, 255, 0.3);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.floating-chat.expanded {
  width: 380px !important;
  height: 600px !important;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.chat-header {
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 6px;
  cursor: pointer;
  background: linear-gradient(
    45deg,
    rgba(0, 12, 46, 0.98),
    rgba(0, 12, 46, 0.95)
  );
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: rgba(0, 12, 46, 0.95);
  overflow: hidden;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  padding: 12px 16px;
  border-radius: 15px;
  max-width: 85%;
  font-size: 0.9rem;
  line-height: 1.4;
  animation: fadeIn 0.3s ease-out;
}

.chat-input-form {


  display: flex;
  gap: 10px;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  background: rgba(0, 12, 46, 0.98);
}

/* Add any missing animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Floating Chat Styles */
.floating-chat-wrapper {
  position: fixed;
  left: 0;  /* Changed from 2rem to 0 */
  bottom: 0; /* Changed from 2rem to 0 */
  z-index: 1000;
  transition: all 0.3s ease;
}

.floating-chat-container {
  border-radius: 12px 12px 0 0; /* Only round top corners */
  overflow: hidden;
  background: rgba(0, 12, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-bottom: none; /* Remove bottom border */
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
  transform-origin: bottom left;
}

/* Ensure proper expansion */
.floating-chat-container.expanded {
  width: 360px !important;
  height: 600px !important;
  border-radius: 12px 12px 0 0; /* Maintain rounded top corners when expanded */
}

/* Fullscreen styles */
.floating-chat-wrapper.fullscreen {
  left: 0; /* Changed from right to left */
  bottom: 0;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
  background: rgba(0, 12, 46, 0.98);
}

.floating-chat-wrapper.fullscreen .floating-chat-container {
  width: 100% !important;
  height: 100vh !important;
  max-width: none !important;
  max-height: none !important;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border: none;
  transform-origin: right top; /* Adjust transform origin for fullscreen */
}

.chat-header {
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(0, 12, 46, 0.98), rgba(0, 18, 66, 0.98));
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.chat-avatar {
  width: 48px;  /* Increased from 40px */
  height: 48px; /* Increased from 40px */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-core {
  width: 20px;  /* Increased from 16px */
  height: 20px; /* Increased from 16px */
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.5);
  animation: pulse 2s infinite ease-in-out;
}

.avatar-ring {
  width: 36px;  /* Increased from 28px */
  height: 36px; /* Increased from 28px */
  border: 2px solid var(--color-neon-blue);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 12px rgba(0, 240, 255, 0.3);
  animation: spin 8s linear infinite;
}

.avatar-ring::before {
  content: '';
  position: absolute;
  top: -5px;    /* Adjusted for larger size */
  left: -5px;   /* Adjusted for larger size */
  right: -5px;  /* Adjusted for larger size */
  bottom: -5px; /* Adjusted for larger size */
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 50%;
  animation: spin 12s linear infinite reverse;
}

.avatar-ring::after {
  content: 'AI';
  position: absolute;
  font-size: 12px;  /* Increased from 10px */
  font-weight: bold;
  color: var(--color-neon-blue);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 8px rgba(0, 240, 255, 0.6);
  letter-spacing: 0.5px;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgba(0, 12, 46, 0.95);
  padding: 16px;
  text-align: left; /* Add this */
}

.message {
  padding: 12px 16px;
  border-radius: 14px;
  max-width: 85%;
  font-size: 0.9rem;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left; /* Add this */
}

.message.user {
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.08), rgba(0, 240, 255, 0.12));
  margin-left: auto;
  color: var(--color-text-primary);
  border: 1px solid rgba(0, 240, 255, 0.15);
  text-align: left; /* Add this */
}

.message.assistant {
  background: rgba(0, 12, 46, 0.95);
  margin-right: auto;
  color: var(--color-text-primary);
  border: 1px solid rgba(0, 240, 255, 0.1);
  text-align: left; /* Add this */
}

.chat-input-form {
  display: flex;
  gap: 12px;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  background: rgba(0, 12, 46, 0.98);
  padding: 16px;
}

.chat-input-form input {
  flex: 1;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  background: rgba(0, 12, 46, 0.8);
  color: var(--color-text-primary);
  font-size: 0.95rem;
}

.chat-input-form input:focus {
  outline: none;
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

.chat-input-form button {
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(135deg, var(--color-neon-blue), var(--color-neon-purple));
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chat-input-form button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

.chat-input-form button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: rgba(0, 240, 255, 0.3);
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* AI Avatar Styles */
.chat-avatar {
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.avatar-core {
  width: 24px;
  height: 24px;
  background: linear-gradient(135deg, var(--color-neon-blue), var(--color-neon-purple));
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.5);
  animation: pulse 2s infinite ease-in-out;
}

.avatar-ring {
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-neon-blue);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.3);
  animation: spin 8s linear infinite;
}

/* Add AI circuit pattern */
.avatar-ring::before {
  content: '';
  position: absolute;
  inset: -6px;
  border: 1.5px solid rgba(0, 240, 255, 0.2);
  border-radius: 50%;
  animation: spin 12s linear infinite reverse;
}

.avatar-ring::after {
  content: 'AI';
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-neon-blue);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 8px rgba(0, 240, 255, 0.6);
  letter-spacing: 0.5px;
}

/* Animation keyframes */
@keyframes pulse {
  0% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(1); opacity: 0.8; }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Message Formatting */
.message {
  padding: 12px 16px;
  border-radius: 14px;
  max-width: 85%;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.3px;
  text-align: left;
}

/* Headers in messages */
.message h1 {
  font-size: 1.8rem;
  margin: 1.5em 0 0.8em;
  font-weight: 600;
  color: var(--color-text-primary);
}

.message h2 {
  font-size: 1.5rem;
  margin: 1.2em 0 0.6em;
  font-weight: 600;
  color: var(--color-neon-blue);
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  padding-bottom: 0.3em;
}

.message h3 {
  font-size: 1.3rem;
  margin: 1em 0 0.5em;
  font-weight: 500;
  color: var(--color-text-primary);
}

/* Code blocks in messages */
.message pre {
  background: rgba(0, 12, 46, 0.95) !important;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1em;
  margin: 1em 0;
  overflow-x: auto;
}

.message code {
  font-family: 'JetBrains Mono', 'Fira Code', monospace;
  font-size: 0.9em;
}

/* Inline code */
.message :not(pre) > code {
  background: rgba(0, 240, 255, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 4px;
  color: var(--color-neon-blue);
}

/* Lists in messages */
.message ul, .message ol {
  margin: 0.8em 0;
  padding-left: 1.5em;
}

.message li {
  margin: 0.4em 0;
  line-height: 1.6;
}

/* Bold text */
.message strong {
  color: var(--color-neon-blue);
  font-weight: 600;
}

/* Links in messages */
.message a {
  color: var(--color-neon-blue);
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 240, 255, 0.3);
  transition: all 0.2s ease;
}

.message a:hover {
  border-bottom-color: var(--color-neon-blue);
}

/* Fullscreen styles */
.floating-chat-wrapper {
  position: fixed;

  z-index: 1000;
  transition: all 0.3s ease;
}

.floating-chat-wrapper.fullscreen {
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  background: rgba(0, 12, 46, 0.98);
}

.floating-chat-wrapper.fullscreen .floating-chat-container {
  width: 100% !important;
  height: 100vh !important;
  max-width: none !important;
  max-height: none !important;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border: none;
}

.floating-chat-wrapper.fullscreen .chat-content {
  height: calc(100vh - 60px); /* Subtract header height */
}

.floating-chat-wrapper.fullscreen .messages-container {
  height: calc(100vh - 140px); /* Subtract header and input form heights */
  max-height: none;
}

.floating-chat-wrapper.fullscreen .chat-input-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  background: rgba(0, 12, 46, 0.98);
  border-top: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 0;
}

.floating-chat-wrapper.fullscreen .chat-header {
  border-radius: 0;
  padding: 0 2rem;
}

/* Adjust the header buttons in fullscreen */
.floating-chat-wrapper.fullscreen .chat-header-right {
  margin-right: 1rem;
}

/* Make messages wider in fullscreen */
.floating-chat-wrapper.fullscreen .message {
  max-width: 70%;
}

/* Add some responsive adjustments */
@media (max-width: 768px) {
  .floating-chat-wrapper.fullscreen .message {
    max-width: 85%;
  }
  
  .floating-chat-wrapper.fullscreen .chat-input-form {
    padding: 1rem;
  }
}

/* Header buttons container */
.chat-header-right {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

/* Common header button styles */
.header-btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  cursor: pointer;
  transition: all 0.2s ease;
}

.header-btn:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.15);
}

/* Fullscreen button specific styles */
.fullscreen-btn {
  font-size: 1.2rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.fullscreen-btn svg {
  width: 20px;
  height: 20px;
  transition: all 0.2s ease;
}

.fullscreen-btn:hover svg {
  color: rgba(0, 240, 255, 0.9);
}

/* Clear chat button specific styles */
.clear-chat-btn {
  border: 1px solid rgba(123, 0, 255, 0.2);
  background: rgba(123, 0, 255, 0.1);
  color: var(--color-neon-purple);
}

.clear-chat-btn:hover {
  background: rgba(123, 0, 255, 0.15);
  color: rgba(123, 0, 255, 0.9);
}

/* Adjust header spacing in fullscreen */
.floating-chat-wrapper.fullscreen .chat-header {
  padding: 0 2rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 240, 255, 0.15);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-btn {
    width: 32px;
    height: 32px;
  }
  
  .fullscreen-btn svg {
    width: 18px;
    height: 18px;
  }
}

/* Table styles in messages */
.message table {
  width: 100%;
  margin: 1.2em 0;
  border-collapse: collapse;
  background: rgba(0, 12, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  overflow: hidden;
  font-size: 0.9em;
  line-height: 1.4;
}

.message th {
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  font-weight: 600;
  text-align: left;
  padding: 12px 16px;
  border-bottom: 2px solid rgba(0, 240, 255, 0.2);
  white-space: normal; /* Allow text wrapping */
  min-width: 150px; /* Minimum column width */
}

.message td {
  padding: 10px 16px;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  color: var(--color-text-primary);
  white-space: normal; /* Allow text wrapping */
  vertical-align: top; /* Align content to top */
}

/* Handle long content */
.message td p {
  margin: 0;
  padding: 0;
}

/* Hover effect on table rows */
.message tr:hover {
  background: rgba(0, 240, 255, 0.05);
}

/* Last row shouldn't have a border */
.message tr:last-child td {
  border-bottom: none;
}

/* Responsive tables */
@media (max-width: 768px) {
  .message table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .message th,
  .message td {
    min-width: 120px;
  }
}

/* Fix for ASCII-style tables */
.message pre:has(code:contains("|")) {
  background: transparent !important;
  border: none;
  padding: 0;
  margin: 0;
}

.message code:contains("|") {
  font-family: var(--font-primary) !important;
  white-space: normal;
}

/* Table caption if needed */
.message table caption {
  padding: 10px;
  color: var(--color-neon-blue);
  font-style: italic;
  text-align: left;
}

/* Last message extra spacing */
.message:last-child {
  margin-bottom: 2rem; /* Extra space after last message */
}

/* Fullscreen adjustments */
.floating-chat-wrapper.fullscreen .messages-container {
  padding-bottom: 6rem; /* Even more space in fullscreen mode */
}

.floating-chat-wrapper.fullscreen .chat-input-form {
  padding: 2rem; /* Larger padding in fullscreen */
}

/* Add a gradient fade effect above the input */
.chat-content::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 12, 46, 0.98)
  );
  pointer-events: none;
  z-index: 1;
}

/* Prevent auto-scroll to chat */
.jaira-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 1rem;
}

.chat-section {
  position: relative;
  margin-top: auto;
  pointer-events: none; /* Allow clicking through the section */
}

.chat-section > * {
  pointer-events: auto; /* Re-enable clicking for chat component */
}

/* Update floating chat container styles */
.floating-chat-container {
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background: rgba(0, 12, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-bottom: none;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
  transform-origin: bottom left;
  display: flex;  /* Add this */
  flex-direction: column;  /* Add this */
}

/* Update chat header styles */
.chat-header {
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add this */
  padding: 0 12px;
  cursor: pointer;
  background: linear-gradient(45deg, rgba(0, 12, 46, 0.98), rgba(0, 12, 46, 0.95));
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

/* Update collapsed state dimensions */
.floating-chat-container:not(.expanded) {
  width: 60px !important;
  height: 60px !important;
}

/* Ensure avatar stays visible */
.chat-avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0; /* Add this */
}

/* Waitlist Modal Styles */
.waitlist-modal {
  max-width: 500px;
  width: 90%;
}

.waitlist-form {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-platinum);
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 425px;
  padding: 0.75rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  background: rgba(0, 12, 46, 0.5);
  color: var(--color-platinum);
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--color-neon-blue);
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

.interest-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.75rem;
}

.interest-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.interest-checkbox input {
  width: auto;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

/* Submission Status Styles */
.submission-status {
  padding: 2rem;
  text-align: center;
}

.submission-status h3 {
  margin: 1rem 0;
  font-size: 1.5rem;
  color: var(--color-platinum);
}

.submission-status p {
  color: var(--color-text-secondary);
  margin-bottom: 1rem;
}

.status-emoji {
  font-size: 3rem;
  display: block;
  margin-bottom: 1rem;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 240, 255, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-neon-blue);
  margin: 0 auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.retry-button {
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1rem;
  transition: transform 0.2s ease;
}

.retry-button:hover {
  transform: translateY(-2px);
}

/* Provider Select Styles */
.provider-select {
  background: rgba(0, 12, 46, 0.95);
  color: var(--color-neon-blue);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 6px 24px 6px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-right: 8px;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  transition: all 0.2s ease;
  min-width: 100px;
  text-align: center;
}

/* Custom dropdown arrow */
.provider-select-wrapper {
  position: relative;
  margin-right: 8px;
}

.provider-select-wrapper::after {
  content: '▼';
  font-size: 0.8em;
  color: var(--color-neon-blue);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Hover and focus states */
.provider-select:hover {
  border-color: rgba(0, 240, 255, 0.4);
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.1);
}

.provider-select:focus {
  outline: none;
  border-color: var(--color-neon-blue);
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.2);
}

/* Option styles */
.provider-select option {
  background: rgba(0, 12, 46, 0.98);
  color: var(--color-neon-blue);
  padding: 8px;
}

/* Add these styles for the waitlist modal */
.waitlist-content {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 20px; /* Add padding for scrollbar */
  margin-right: -20px; /* Offset padding to maintain alignment */
  scrollbar-width: thin;
  scrollbar-color: var(--color-neon-blue) rgba(0, 12, 46, 0.8);
}

/* Custom scrollbar styles for webkit browsers */
.waitlist-content::-webkit-scrollbar {
  width: 8px;
}

.waitlist-content::-webkit-scrollbar-track {
  background: rgba(0, 12, 46, 0.8);
  border-radius: 4px;
}

.waitlist-content::-webkit-scrollbar-thumb {
  background: var(--color-neon-blue);
  border-radius: 4px;
}

.waitlist-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.8);
}

/* Ensure modal content doesn't overflow */
.modal-content {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

/* Add padding to the bottom of the form to ensure visibility when scrolling */
.waitlist-form {
  padding-bottom: 20px;
}

/* Modal Layout */
.modal-content.waitlist-modal {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 500px;
  width: 90%;
}

/* Fixed Header */
.modal-header {
  position: sticky;
  top: 0;
  background: var(--color-background);
  padding: 1.5rem 1.5rem 1.5rem 0.5rem; /* Much smaller left padding */
  border-bottom: 1px solid var(--color-border);
  z-index: 1;
}

/* Scrollable Content */
.modal-scroll-content {
  flex: 1;
  overflow-y: auto;

  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Fixed Footer */
.modal-footer {
  position: sticky;
  bottom: 0;
  background: var(--color-background);
  padding: 1.5rem 1.5rem 1.5rem 0.5rem; /* Much smaller left padding */
  border-top: 1px solid var(--color-border);
  text-align: right;
}

/* Ensure form takes full width */
.waitlist-form {
  width: 100%;
}

/* Status screens should be centered */
.submission-status {
  padding: 2rem;
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content.waitlist-modal {
    max-height: 100vh;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
