:root {
  --gradient-1: linear-gradient(45deg, #00F0FF, #7B00FF);
  --gradient-2: linear-gradient(45deg, #7B00FF, #00F0FF);
  --text-color: #00F0FF;
  --background-color: #000C2E;
}

.App {
  position: relative;
  min-height: 100vh;
  background: #000C2E;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  transition: all 0.3s ease;
  z-index: 1000;
  background: rgba(0, 12, 46, 0.95);
  backdrop-filter: blur(10px);
}

.navbar.scrolled {
  background: rgba(0, 12, 46, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1.8rem;
  font-weight: 800;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a {
  color: var(--color-platinum);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.nav-links a:hover {
  color: var(--color-neon-blue);
}

.cta-button {
  background: var(--gradient-1);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 240, 255, 0.3);
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  color: var(--color-platinum);
}

.hero h1 {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  font-size: 1.5rem;
  max-width: 800px;
  opacity: 0.9;
}

.particle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
}

.hero-title {
  font-size: 4.5rem;
  text-align: center;
  margin-top: 180px;
  background: linear-gradient(45deg, 
    var(--color-neon-blue), 
    var(--color-neon-purple)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(0, 240, 255, 0.3);
  position: relative;
}

.gradient-text {
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: var(--color-platinum);
  opacity: 0.9;
  max-width: 600px;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.cta-container {
  display: flex;
  gap: 1.5rem;
}

.cta-primary, .cta-secondary {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.cta-primary {
  background: var(--gradient-1);
  color: white;
  position: relative;
  overflow: hidden;
}

.cta-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--gradient-2);
  transition: left 0.3s ease;
}

.cta-primary:hover::before {
  left: 0;
}

.cta-secondary {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: var(--color-platinum);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.cta-secondary:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.button-text, .button-icon {
  position: relative;
  z-index: 1;
}

.button-icon {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.cta-primary:hover .button-icon,
.cta-secondary:hover .button-icon {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem 2rem;
    top: 0;
  }
  
  .hero-container {
    padding-top: 100px;
  }

  .hero {
    padding: 0 2rem;
  }

  .hero-title {
    font-size: 2.5rem;
    margin-top: 120px;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .nav-links {
    display: none;
  }
}

.page-container {
  min-height: calc(100vh - 80px); /* Adjust based on your navbar height */
  padding: 2rem;
  background: var(--background-color, #020B1C);
  color: var(--text-color, #fff);
}

.page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.page-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #3490dc, #6574cd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-description {
  font-size: 1.2rem;
  color: #a0aec0;
  margin-bottom: 3rem;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.solution-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.solution-card:hover {
  transform: translateY(-5px);
}

.solution-card h3 {
  color: #3490dc;
  margin-bottom: 1rem;
}

.tech-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.tech-feature {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.tech-feature:hover {
  transform: translateY(-5px);
}

.tech-feature h3 {
  color: #3490dc;
  margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }
  
  .page-title {
    font-size: 2rem;
  }
  
  .page-description {
    font-size: 1rem;
  }
}

/* About Page Styles */
.about-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.about-section {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
}

.about-section h3 {
  color: #3490dc;
  margin-bottom: 1rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.team-member {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.member-avatar {
  width: 120px;
  height: 120px;
  background: #3490dc;
  border-radius: 60px;
  margin: 0 auto 1rem;
}

/* Investors Page Styles */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.metric-card {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.metric-card h3 {
  color: #3490dc;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.investor-section {
  text-align: center;
  margin: 3rem 0;
}

.contact-button {
  background: linear-gradient(45deg, #3490dc, #6574cd);
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 1rem;
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.partner-logo {
  background: rgba(255, 255, 255, 0.05);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

/* Careers Page Styles */
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.benefit-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
}

.benefit-card h4 {
  color: #3490dc;
  margin-bottom: 1rem;
}

.job-list {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
}

.job-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apply-button {
  background: linear-gradient(45deg, #3490dc, #6574cd);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.apply-button:hover {
  transform: translateY(-2px);
  transition: transform 0.3s ease;
}

.job-info {
  flex-grow: 1;
}

.job-tag {
  display: inline-block;
  background: rgba(0, 240, 255, 0.1);
  color: var(--color-neon-blue);
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.location-section {
  margin-top: 4rem;
}

.office-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card h4 {
  color: var(--color-neon-blue);
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .job-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .apply-button {
    width: 100%;
  }
}

/* Projects Page Styles */
.featured-project {
  padding: 2rem 0;
}

.project-header {
  text-align: center;
  margin-bottom: 3rem;
}

.project-header h2 {
  font-size: 2.5rem;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.project-tagline {
  font-size: 1.2rem;
  color: var(--color-platinum);
  opacity: 0.9;
}

.project-overview {
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

.feature-card h4 {
  color: var(--color-neon-blue);
  margin-bottom: 1rem;
}

.use-cases {
  text-align: center;
}

.use-cases h3 {
  margin-bottom: 2rem;
}

.use-cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.use-case {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.use-case:hover {
  transform: translateY(-5px);
}

.use-case h4 {
  color: var(--color-neon-blue);
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .project-header h2 {
    font-size: 2rem;
  }
  
  .project-tagline {
    font-size: 1rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
}

/* SynapseX Specific Styles */
.synapse-x {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.user-case {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.user-case:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

.user-case h4 {
  color: var(--color-neon-blue);
  margin-bottom: 0.5rem;
}

.target-users {
  text-align: center;
  margin-top: 4rem;
}

.target-users h3 {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .synapse-x {
    margin-top: 3rem;
    padding-top: 3rem;
  }
  
  .users-grid {
    grid-template-columns: 1fr;
  }
}

/* Add these new styles */
.features-section {
  padding: 2rem 2rem;
  background: rgba(0, 12, 46, 0.7);
}

.features-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

.feature-block {
  background: rgba(0, 12, 46, 0.95);
  padding: 2.5rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  text-align: center;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.feature-block:hover {
  transform: translateY(-5px);
  border-color: var(--color-neon-blue);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  background: rgba(0, 240, 255, 0.1);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.feature-block h3 {
  color: var(--color-neon-blue);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature-block p {
  color: var(--color-platinum);
  opacity: 0.8;
  line-height: 1.6;
}

.cta-section {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary-cta {
  background: linear-gradient(45deg, var(--color-neon-blue), var(--color-neon-purple));
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .features-section {
    padding: 3rem 1rem;
  }
  
  .cta-section h2 {
    font-size: 2rem;
  }
}

/* Update metrics bar spacing */
.metrics-bar {
  margin-bottom: 1rem; /* Further reduced spacing */
}

/* Update news section wrapper spacing */
.news-section-wrapper {
  width: 100%;
  background: rgba(0, 12, 46, 0.8);

  margin-top: 2rem; /* Further reduced spacing */
}

/* Adjust news section title size */
.news-container .section-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .hero-container {
    padding-top: 2rem;
  }
  
  .news-section-wrapper {
    padding: 1.5rem 1rem;
  }
  
  .news-container .section-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

/* Footer Styles */
.footer {
  background: rgba(0, 12, 46, 0.95);
  padding: 3rem 2rem 1.5rem;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-bottom: 2rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.footer-section h4 {
  color: var(--color-neon-blue);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.footer-section a, 
.footer-section p {
  color: var(--color-platinum);
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.footer-section a:hover {
  color: var(--color-neon-blue);
  opacity: 1;
  transform: translateX(5px);
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.disclaimer {
  color: var(--color-platinum);
  opacity: 0.6;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  max-width: 800px;
  margin: 0 auto 1rem;
}

.copyright {
  color: var(--color-platinum);
  opacity: 0.8;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 1rem 1rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-section {
    text-align: center;
  }

  .footer-section a:hover {
    transform: none;
  }

  .disclaimer {
    font-size: 0.8rem;
  }
}

.social-icon {
  margin-right: 0.5rem;
}

.footer-section a {
  display: flex;
  align-items: center;
}

/* Update news ticker styles */
.news-ticker {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: rgba(0, 12, 46, 0.95);
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 999;
  backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

/* Update startup section spacing */
.startup-section {
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 1rem 2rem;
  background: rgba(0, 12, 46, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  backdrop-filter: blur(10px);
}
