.paper-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.paper-content {
  background: var(--color-background-secondary);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.paper-abstract {
  background: rgba(0, 240, 255, 0.03);
  padding: 20px;
  margin: 20px 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.paper-content h1 {
  color: var(--color-neon-blue);
  font-size: 2.5rem;
  margin-bottom: 24px;
}

.paper-content h2 {
  color: var(--color-neon-blue);
  font-size: 1.8rem;
  margin: 32px 0 16px;
}

.paper-content h3 {
  color: var(--color-platinum);
  font-size: 1.4rem;
  margin: 24px 0 12px;
}

.paper-content p {
  color: var(--color-platinum);
  line-height: 1.8;
  margin-bottom: 16px;
}

.paper-content ul {
  margin: 16px 0;
  padding-left: 24px;
}

.paper-content li {
  color: var(--color-platinum);
  margin: 8px 0;
}

.references {
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid var(--color-border);
}

.references a {
  color: var(--color-primary);
  text-decoration: none;
}

.references a:hover {
  text-decoration: underline;
}

.paper-author {
  margin: 20px 0;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-platinum);
}

.author-name {
  color: var(--color-neon-blue);
  font-weight: 500;
}

.author-title {
  color: var(--color-platinum);
  opacity: 0.8;
  margin-left: 10px;
}

.publication-date {
  color: var(--color-platinum);
  opacity: 0.7;
  margin-top: 4px;
}

.reference-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.reference-item {
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.reference-item:hover {
  transform: translateX(5px);
}

.reference-author {
  color: var(--color-neon-blue);
  font-weight: 600;
}

.reference-link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-decoration: none;
  padding: 1rem;
  border-radius: 8px;
  background: rgba(0, 240, 255, 0.03);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.reference-link:hover {
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.2);
}

.reference-content {
  flex: 1;
}

.reference-title {
  display: block;
  color: var(--color-neon-blue);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.reference-description {
  color: var(--color-text-secondary);
  font-size: 0.9rem;
  margin: 0;
  opacity: 0.8;
}

.link-arrow {
  color: var(--color-neon-blue);
  font-size: 1.2rem;
  margin-left: 1rem;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.reference-link:hover .link-arrow {
  opacity: 1;
  transform: translateX(0);
}

.paper-footer {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  text-align: center;
  font-size: 0.9rem;
  color: var(--color-platinum);
  opacity: 0.8;
}

.paper-section {
  margin: 30px 0;
}

.highlight-box {
  background: rgba(255, 152, 0, 0.1);
  padding: 15px;
  margin: 15px 0;
  border-left: 4px solid var(--color-neon-blue);
  border-radius: 4px;
}

.paper-references {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.paper-references ol {
  padding-left: 20px;
}

.paper-references li {
  margin: 10px 0;
  color: var(--color-platinum);
  opacity: 0.9;
} 